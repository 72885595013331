import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "@accrosoft-ltd/accropress-websites-components/dist/esm/seo"

import WigLoader from "@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader"

import "../components/wig-bundle.css"

import config from "../../config.json"

require("es6-promise").polyfill()

export default function DefaultPage({
  pageContext: {
    integratorData,
    contentMetaData,
    contentDataData,
    navigationData,
    navResultData,
    entireLocationsList,
    customFieldsJSON,
  },
  props,
}) {
  return (
    <Layout {...props}>
      <SEO
        navResultData={navResultData}
        contentDataData={contentDataData}
        integratorData={integratorData}
        contentMetaData={contentMetaData}
      />

      <div className="bannerWrapper">
        <div
          className="st-philips-banner-image"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: `url(${contentDataData[0].children[2].value})`,
          }}
        ></div>
        <div className="bannerTextWrapper">
          <div className="bannerText">
            <div className="bannerContent">
              <h1>{contentDataData[0].children[0].value}</h1>
              {contentDataData[0].children[1].value}
            </div>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          class="hb-curve"
        >
          <polygon fill="#fff" points="0,100 100,0 100,100"></polygon>
        </svg>
      </div>

      <div className="page__header-container page__header-container--blog-landing">
        <div className="container">
          <div
            className="row justify-content-center page__header page__header--landing blog-landing__header page__header--blog-landing"
            id="contact-us"
          >
            <div className="col-12 col-lg-8">
              <WigLoader
                contentDataData={contentDataData}
                contentMetaData={contentMetaData}
                contentDataArray="0"
                contentDataChild="3"
                AccroPressProdEndpoint={"https://api.accropress.com/api/"}
                AccroPressLocalEndpoint={"https://api.accropress.com/api/"}
                integratorID={"d962dd04-2ba4-4820-ac62-74041bbff7bd"}
                integratorAccessToken={"4232557e-d5eb-4de4-9ebd-819ddf68a1b9"}
              />
            </div>
            <div className="col-12 col-lg-4">
              <div className="sidebar">
                <h2>{contentDataData[0].children[4].value}</h2>
                <ul className="contact-details">
                  {contentDataData[0].children[5].children.map((column, i) => (
                    <li key={i}>
                      <img src={column[0].value} />
                      <WigLoader
                        weducWebsitesID={config.weducWebsitesID}
                        weducContainer={config.weducContainerID}
                        weducEntity={config.weducEntityID}
                        contentDataData={contentDataData}
                        contentMetaData={contentMetaData}
                        contentDataArray="0"
                        contentDataChild="2"
                        customDataArray={column[1].value}
                        AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                        AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                        integratorID={config.integratorID}
                        integratorAccessToken={config.integratorAccessToken}
                        imageFormatting={
                          process.env.IMAGE_FORMATTING_SECRET ||
                          process.env.GATSBY_IMAGE_FORMATTING_SECRET
                        }
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-landing">
        <div className="container">
          <div className="row clearfix">
            <div className="col-md-12 column">
              <div className="stphilips-spacing"></div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
